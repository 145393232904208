import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SourceInfo } from '_debugger';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideocallService {
  private socket: any;
  private peerConnection: RTCPeerConnection;
  private localStream: MediaStream;
  public incomingCallFrom: string | null = null; 
  public isLoading: boolean = false;
  private incomingRingtone: HTMLAudioElement;
  private outgoingRingtone: HTMLAudioElement;
  public callStatusChanged = new EventEmitter<boolean>();
  private callStartTime: number | null = null;
  private callTimer: any;
  public callDuration: string = '00:00';

  private rejectCalldataSubject = new Subject<any>();
  rejectCallData$ = this.rejectCalldataSubject.asObservable();
  rejectCallSendData(data: any) {
    this.rejectCalldataSubject.next(data);
  }

  private disconnectCalldataSubject = new Subject<any>();
  disconnectCallData$ = this.disconnectCalldataSubject.asObservable();
  disconnectCallData(data: any) {
    this.disconnectCalldataSubject.next(data);
  }
 
  constructor(private Http: HttpClient, private toastr: ToastrService) {
    const io = require('socket.io-client');
    this.socket = io(environment.socket_base_url);
    // Initialize separate ringtones for incoming and outgoing calls
    this.incomingRingtone = new Audio('https://api.itsgametime.com/ringtone.mp3');
    this.outgoingRingtone = new Audio('https://api.itsgametime.com/dialtone.mp3');

    this.setupSocketEvents();
    this.initializePeerConnection();
  }

  loginUserData:any;
  public loginUsersetArray(array: any) {
    this.loginUserData = array;
    // console.log('Array set in service:', this.loginUserData);
  }

  chatUserData:any;
  public chatUsersetArray(array: any) {
    this.chatUserData = array;
    // console.log('Array set in service:', this.chatUserData);
  }

 
  private setupSocketEvents() {
    var $this = this;
    this.socket.on('incomingCall', (res) => {
      if(res && res.to == $this.loginUserData.id){
        console.log(res.srouceInfo.name +" is calling "+res.targetInfo.name)
        // console.log(res);
        
        
        this.toastr.success(
          'Hi ' + res.targetInfo.name + ', ' + res.srouceInfo.name + ' is calling. Please go to the chat section to accept the call.',
          '', // Title (optional, can be empty string if not needed)
          {
            positionClass: 'toast-top-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: false,
            progressBar: false,
          }
        );
        
          this.incomingCallFrom = res.from; 
          this.incomingRingtone.loop = true;
          this.incomingRingtone.play();
        
          // console.log('Incoming call from: ', res.from);
      }
      
      // this.incomingCallFrom = from; 
      // this.incomingRingtone.loop = true;
      // this.incomingRingtone.play();
      // console.log('Incoming call from: ', from);
    });

    this.socket.on('offer', async (data) => {
      if(data.target == $this.loginUserData.id){

        this.outgoingRingtone.loop = false;
        this.outgoingRingtone.pause();

        console.log('Received offer:', data.offer);
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);
        console.log(data.from)
        this.socket.emit('answer', {source:$this.loginUserData.id, target: data.from, answer });
         // Start the call timer
         this.startCallTimer();
      }
     
    });

    this.socket.on('answer', async (data) => {
      if(data.target == $this.loginUserData.id){
      console.log('Received answer:', data.answer);
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
       // Start the call timer
       this.startCallTimer();
      }
    });

    this.socket.on('candidate', async (data) => {
      if(data.target == $this.loginUserData.id){ 
        console.log('Received ICE candidate:', data.candidate);
        await this.peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
      }
    });

    this.socket.on('callDisconnected', (res) => { 
      if(res && (res.loginUserId == $this.loginUserData.id && res.chatUserId == $this.chatUserData.id) || (res.loginUserId == $this.chatUserData.id && res.chatUserId == $this.loginUserData.id)){
        // alert($this.loginUserData.id+ '----' +res.loginUserId+ '----' +res.chatUserId);
         
        // console.log('Call disconnected signal received');
        // this.incomingCallFrom = null;
     
        this.hangupCall();
        this.incomingRingtone.pause();
        this.incomingRingtone.loop = false;
        this.outgoingRingtone.loop = false;
        this.outgoingRingtone.pause();
        this.toastr.success('Your call was disconnected', 'Video Call');
        this.callStatusChanged.emit(false);
        this.disconnectCallData(res);
      }
     
    });

    this.socket.on('callRejected', (res) => {
      if(res && res.target == $this.loginUserData.id){
        console.log('Call rejected signal received');
        this.hangupCall();
        this.incomingRingtone.pause();
        this.incomingRingtone.loop = false; 
        this.outgoingRingtone.loop = false;
        this.outgoingRingtone.pause();

        // this.incomingCallFrom = null;

        this.toastr.success('Your call was rejected', 'Video Call');
        this.callStatusChanged.emit(false);
        this.rejectCallSendData(res);
      }
     
    });
  }

  
  private initializePeerConnection() {
    this.peerConnection = new RTCPeerConnection({
 
      iceServers: [{
        "urls": "stun:global.stun.twilio.com:3478"
      },
      {
        "username": "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        "credential": "tE2DajzSJwnsSbc123",
        "urls": "turn:global.turn.twilio.com:3478?transport=udp"
      },
      {
        "username": "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        "credential": "tE2DajzSJwnsSbc123",
        "urls": "turn:global.turn.twilio.com:3478?transport=tcp"
      },
      {
        "username": "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        "credential": "tE2DajzSJwnsSbc123",
        "urls": "turn:global.turn.twilio.com:443?transport=tcp"
      }]
    });

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        this.socket.emit('candidate', {
          source:this.loginUserData.id,
          target: this.incomingCallFrom,
          candidate: event.candidate
        });
      }
    };

    this.peerConnection.ontrack = (event) => {
      this.handleTrackEvent(event);
    };
  }

  private async handleTrackEvent(event: RTCTrackEvent) {
    const remoteVideo = document.getElementById('remoteVideo') as HTMLVideoElement;
    if (remoteVideo) {
      const stream = event.streams[0];
      remoteVideo.srcObject = stream;
      await this.acceptCallRemote();
      this.isLoading = false;
    }
  }


  replaceVideoTrack(newVideoTrack: MediaStreamTrack) {
    const videoSender = this.peerConnection.getSenders().find(s => s.track && s.track.kind === 'video');

    if (videoSender) {
        videoSender.replaceTrack(newVideoTrack);
        console.log('Video track replaced successfully:', newVideoTrack);
    } else {
        console.error('Video sender not found');
    }
  }

  ensureAudioTrack(newAudioTrack: MediaStreamTrack) {
    const audioSender = this.peerConnection.getSenders().find(s => s.track && s.track.kind === 'audio');

    if (audioSender) {
        if (audioSender.track.readyState === 'ended') {
            console.log('Audio track has ended. Replacing with a new one.');
            audioSender.replaceTrack(newAudioTrack);
        } else {
            console.log('Audio track is active:', audioSender.track);
        }
    } else {
        console.error('Audio sender not found');
    }
  }


  getSenders() {
      return this.peerConnection.getSenders();
  }
  async register(username: string) {
    this.socket.emit('register', username);
  }

  async callUser(srouceUsername: string,targetUsername: string,srouceInfo: string,targetInfo: string) {
    this.outgoingRingtone.loop = true;
    this.outgoingRingtone.play();
    this.socket.emit('call', { from: srouceUsername, to: targetUsername,srouceInfo:srouceInfo,targetInfo:targetInfo });
    
    // this.socket.emit('checkUserOnline', targetUsername, (isOnline: boolean) => {
    //   if (isOnline) {
    //     this.outgoingRingtone.loop = true;
    //     this.outgoingRingtone.play();
    //     this.socket.emit('call', { from: srouceUsername, to: targetUsername,srouceInfo:srouceInfo,targetInfo:targetInfo });
    //   } else {
    //     this.toastr.success('User is not online.', 'Video Call');
    //     console.log('User is not online.');
    //   }
    // });
  }

  private async waitForIceGatheringComplete() {
    return new Promise<void>((resolve) => {
      if (this.peerConnection.iceGatheringState === 'complete') {
        resolve();
      } else {
        const checkState = () => {
          if (this.peerConnection.iceGatheringState === 'complete') {
            this.peerConnection.removeEventListener('icegatheringstatechange', checkState);
            resolve();
          }
        };
        this.peerConnection.addEventListener('icegatheringstatechange', checkState);
      }
    });
  }

  
  async startCall(srouceUsername: string,targetUsername: string,srouceInfo: string,targetInfo: string) {
    await this.getMediaDevices();
    this.callDuration='00:00';
    this.callUser(srouceUsername,targetUsername,srouceInfo,targetInfo);
  }

  async acceptCall() {
    this.callDuration='00:00';
    this.callStatusChanged.emit(true);
    this.incomingRingtone.pause();
    this.outgoingRingtone.pause(); // Pause the outgoing ringtone
    this.outgoingRingtone.loop = false; // Disable looping for the outgoing ringtone
  
    this.isLoading = true;
    try {
      await this.getMediaDevices();
      if (this.incomingCallFrom) {
        try {
          console.log(this.incomingCallFrom);
          const offer = await this.peerConnection.createOffer();
          await this.peerConnection.setLocalDescription(offer);
          await this.waitForIceGatheringComplete();
  
          this.socket.emit('offer', { source: this.loginUserData.id, target: this.incomingCallFrom, offer });
  
         
        } catch (error) {
          console.error('Error creating or sending offer:', error);
          this.isLoading = false;
          return;
        }
      }
    } catch (error) {
      console.error('Error getting media devices:', error);
      this.isLoading = false;
      return;
    }
  }
  

  async acceptCallRemote() {
    try {
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      await this.waitForIceGatheringComplete();
     
      this.socket.emit('offer', {source: this.loginUserData.id, target: this.incomingCallFrom, offer });
      // this.incomingCallFrom = null; 
    } catch (error) {
      console.error('Error creating or sending offer:', error);
      this.isLoading = false;
      return;
    }
  }

  private startCallTimer() {
    this.callStartTime = Date.now();
    this.callTimer = setInterval(() => {
      if (this.callStartTime) {
        const elapsedTime = Date.now() - this.callStartTime;
        this.callDuration = this.formatTime(elapsedTime);
      }
    }, 1000);
  }
  
  private stopCallTimer() {
    if (this.callTimer) {
      clearInterval(this.callTimer);
      this.callTimer = null;
    }
    this.callStartTime = null;
  }
  
  private formatTime(ms: number): string {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
  }
  
  private pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  async rejectCall() {
    this.incomingRingtone.pause();
    this.outgoingRingtone.pause(); // Stop the outgoing ringtone
    this.outgoingRingtone.loop = false; // Disable looping for the outgoing ringtone
    if (this.incomingCallFrom) {
      this.socket.emit('rejectCall', { target: this.incomingCallFrom });
      this.incomingCallFrom = null; 
      this.callStatusChanged.emit(false);
    }
  }


  async disconnectCall(loginUserId,chatUserId) {
    // if(this.incomingCallFrom){
    //   console.log('Sending disconnectCall signal', this.incomingCallFrom);
    // }
    console.log("loginUser Id", loginUserId);
    console.log("chatUser Id", chatUserId);
    this.socket.emit('disconnectCall', { loginUserId: loginUserId, chatUserId: chatUserId });
    this.hangupCall();
    this.callStatusChanged.emit(false);
   
  }

  async getMediaDevices() {
    try {
      this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      this.localStream.getTracks().forEach(track => {
        this.peerConnection.addTrack(track, this.localStream);
      });
      const localVideo = document.getElementById('localVideo') as HTMLVideoElement;
      if (localVideo) {
        localVideo.srcObject = this.localStream;
        localVideo.muted = true;
      }
    } catch (error) {
      console.error('Error accessing media devices.', error);
      alert('Could not access your camera. Please check browser permissions and ensure no other application is using the camera.');
    }
  }

  hangupCall() {
    console.log('Hanging up the call');
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }
    this.initializePeerConnection();
    const remoteVideo = document.getElementById('remoteVideo') as HTMLVideoElement;
    if (remoteVideo) {
      remoteVideo.srcObject = null;
    }
    const localVideo = document.getElementById('localVideo') as HTMLVideoElement;
    if (localVideo) {
      localVideo.srcObject = null;
    }
    if (this.localStream) {
      this.localStream.getTracks().forEach(track => track.stop());
      this.localStream = null;
    }
    this.isLoading = false;
    this.incomingRingtone.pause();
    this.outgoingRingtone.pause();
    this.incomingRingtone.loop = false;
    this.outgoingRingtone.loop = false;
  
    // Stop the call timer
    this.stopCallTimer();
  }
  
}
